<template>
  <div class="staking-input" :class="{light}">
    <div class="staking-input-left">
      <div class="staking-input-label"> {{ $t(left_label) }} </div>
      <input type="text" v-model="input" class="staking-input-field">
    </div>
    <div class="staking-input-right">
      <div class="staking-input-label">
        {{ $t(right_label) }}: <span> {{balance}} </span>
      </div>
      <s-select
        v-model="pool"
        :options="cards"
        value_field="name"
        custom_trigger
        custom_option
        small
        :light="light"
      >
        <template v-slot:trigger="{item}">
          <img :src="require(`@/assets/img/solana/cards/${item.image}`)" :alt="item.name">
          <span>{{ item.name }}</span>
        </template>

        <template v-slot:option="{option}">
          <img :src="require(`@/assets/img/solana/cards/${option.image}`)" :alt="option.name">
          <span>{{ option.name }}</span>
        </template>
      </s-select>
    </div>
  </div>
</template>

<script>
import SSelect from '@/components/Solana/SSelect';

export default {
  name: 'StakingInput',
  components: { SSelect },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  computed: {
    left_label() {
      return this.options?.labels.left ?? 'solana.input';
    },
    right_label() {
      return this.options?.labels.right ?? 'solana.balance';
    },
  },

  data() {
    return {
      input: 0,
      balance: 0,
      pool: {
        image: '2.png',
        name: 'SHT',
      },
      cards: [
        {
          image: '1.png',
          name: 'SHT/ETH',
        },
        {
          image: '2.png',
          name: 'SHT',
        },
        {
          image: '3.png',
          name: 'SHT',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/style/_colors';

.staking-input {
  background: #181818;
  border-radius: 1.2rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  justify-content: space-between;
  &-left{
    max-width: 80%;
    overflow-x: hidden;
  }

  &-field{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: $s-text-color;
    height: 2.8rem;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    &:focus, &:active{
      outline: none;
    }
  }

  &-label{
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.2rem;
    letter-spacing: 0.01em;
    color: #AEB3E0;
    margin-bottom: 0.4rem;
    span{
      color: #8982E2;
    }
  }
  &.light{
    background: white;
    .s-select{
      &-trigger{
        border-color: #1D1E21;
      }
    }
  }
  &.light &{
    &-label{
      color: #3B7982;
    }
    &-field{
      color: #335CBE;
    }
  }
}
</style>
